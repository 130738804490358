<template>
  <div class="flex min-h-screen flex-col">
    <Header />
    <main class="relative flex flex-1 flex-col">
      <NuxtLoadingIndicator />
      <div class="flex flex-1">
        <slot />
      </div>
    </main>
    <Footer />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { watch, useCookie } from '#imports'
import { useGuestCartStore } from '~/store/guestCart'
import { useGuestStore } from '~/store/guest'

const guestCartStore = useGuestCartStore()
const { guest } = storeToRefs(useGuestStore())

const guestCartCookie = useCookie('guestCart')

watch(
  () => guest.value.token || guest.value.loggedInToken,
  async () => {
    if (guest.value.token || guest.value.loggedInToken) {
      const parsedCookie =
        typeof guestCartCookie.value === 'string'
          ? JSON.parse(guestCartCookie.value)
          : guestCartCookie.value

      const needsCart = !parsedCookie || (parsedCookie && !parsedCookie.cartId)

      if (guest.value && guest.value.storeId) {
        if (needsCart) {
          await guestCartStore.createCart()
        }
      }
    }
  },
  { immediate: true },
)
</script>
